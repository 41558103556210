import React from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';

const NotFoundPage = () => (
  <Layout>
    <HelmetDatoCms title="404, Page Not Found" />
    <main>
      <PageHeader heading="404, Page Not Found" />
    </main>
  </Layout>
);

export default NotFoundPage;
